
@font-face {
  font-family: 'pirulen';
  src: url('./assets/pirulen.ttf') format("truetype");
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: aliceblue;
}

.page {
    display: flex;
    width: 100%;
    height: 105vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.page video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

a {
  text-decoration: none;
  
}

.link-color {
  color: black;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 105vh;
}

.page__content {
   
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'pirulen', Courier, monospace;
    
}


.page h1 {
font-size: 3rem;
margin-bottom: 1rem;
max-width: 800px;
margin-top: 80px;
}

.page h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
    max-width: 800px;
    
    }

.page h3 {
    font-weight: 300;
    max-width: 400px;
    margin-bottom: 1rem;
}


.flip-clock {
    --fcc-flip-duration: 0.5s; /* transition duration when flip card */
    --fcc-digit-block-width: 30px; /* width of digit card */
    --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
    --fcc-digit-font-size: 30px; /* font size of digit */
    --fcc-digit-color: black; /* color of digit */
    --fcc-label-font-size: 10px; /* font size of label */
    --fcc-label-color: #806c6c; /* color of label */
    --fcc-background: white; /* background of digit card */
    --fcc-divider-color: black; /* color of divider */
    --fcc-divider-height: 1px; /* height of divider */
    --fcc-separator-size: 6px; /* size of colon */
    --fcc-separator-color: white; /* color of colon */
  }

  .flip-clock {
    font-family: 'Orbitron', cursive;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  @media (min-width: 800px) {
    .page h1 {
    font-size: 3.6rem;
    font-weight: 100;
    letter-spacing: 1vh;
    
  }
  .page h2 {
    font-size: 1.2rem;
    letter-spacing: 0.5rem;
  }
  .page h3 {
    max-width: 600px;
    
  }
  .flip-clock {
    --fcc-digit-block-width: 45px; /* width of digit card */
    --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
    --fcc-digit-font-size: 30px; /* font size of digit */
    --fcc-digit-color: black; /* color of digit */
    --fcc-label-font-size: 14px; /* font size of label */
  }
}

.btn {
    width: 180px;
    height: 36px;
    background: whitesmoke;
    color: black;
    font-size: 1rem;
    font-family: "pirulen";
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 0.4rem;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}




  .logo {
    height: 200px;
    margin-bottom: 7%;
    margin-top: 6%;
  }
